<template>
  <div class="border-t border-white/10">
    <Container class="mt-[12vw] md:mt-[6vw] md:pb-[5.625vw]">
      <SupportBlueHeading>Game specific FAQ</SupportBlueHeading>

      <SupportHeading class="text-white mt-[10vw] md:mt-[6.75vw]">
        Please pick a game:
      </SupportHeading>

      <div class="hidden md:grid md:grid-cols-8 md:gap-x-[1.75vw] md:gap-y-[2vw] md:mt-[3vw]">
        <SupportGameCard v-for="item in games"
                         :key="item.id"
                         @click="handleGameSelect(item.title)"
                         :selected="item.title === props.game"
                         :title="item.title"
                         :image="item.image"
        />
      </div>
    </Container>

    <div v-if="games && games.length > 0"
         class="mt-[8vw] md:hidden mb-[16vw]"
    >
      <Slider :per-view="2.5"
              title=""
      >
        <SupportGameCard v-for="item in games"
                         :key="item.title"
                         class="keen-slider__slide"
                         @click="handleGameSelect(item.title)"
                         :selected="item.title === props.game"
                         :title="item.title"
                         :image="item.image"
        />
      </Slider>
    </div>

    <div ref="platformContainer" />
    <Container v-if="game">
      <SupportHeading class="text-white md:mt-[6.75vw]">
        What is your device type?
      </SupportHeading>

      <div class="flex flex-col md:flex-row justify-center space-y-[4vw] md:space-y-0 md:space-x-[2vw] text-white md:mt-[3vw] mt-[6vw]">
        <SupportButton v-for="item in game.platforms"
                       :key="item.id"
                       @click="handlePlatformSelect(item.id)"
                       :selected="item.id === props.platform"
        >
          {{ item.title }}
        </SupportButton>
      </div>
    </Container>

    <div ref="categoriesContainer"
         class="mt-[20vw] md:mt-[10vw]"
    />
    <div v-if="platform"
         class="bg-white md:pb-[10vw] pb-[20vw]"
    >
      <Container>
        <div class="pt-[20vw] md:pt-[7.5vw] grid grid-cols-1 md:grid-cols-12 gap-y-[16vw] md:gap-y-[7vw]">
          <SupportCategory v-for="category in platform.categories"
                           :key="category.title"
                           :title="category.title"
                           :items="category.questions"
          />
        </div>
      </Container>
    </div>

    <Container class="bg-indigo py-[20vw] md:py-[10vw]">
      <slot />
    </Container>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
import { computed, nextTick, onMounted, ref } from 'vue';

const { $lenisScroll } = useNuxtApp();


type SupportGameSpecificProps = {
    games: any[]
    game?: string | string[];
    platform?: string | string[];
}

const props = defineProps<SupportGameSpecificProps>();
const emit = defineEmits(['update:game', 'update:platform']);
const platformContainer = ref(null);
const categoriesContainer = ref(null);

const game = computed(() => {
  if (props.games.length === 0) {
    return null;
  }

  return props.games.find(item => item.title === props.game);
});

const platform = computed(() => {
  if (!game.value) {
    return null;
  }

  return game.value.platforms.find((item: any) => item.id === props.platform);
});

function handleGameSelect(value: string ) {
  emit('update:game', value);

  nextTick(() => {
    if (platformContainer.value) {
      $lenisScroll(platformContainer.value);
    }
  });
}

function handlePlatformSelect(value: string) {
  emit('update:platform', value);

  nextTick(() => {
    if (categoriesContainer.value) {
      $lenisScroll(categoriesContainer.value);
    }
  });
}

onMounted(() => {
  // if game is set, scroll to game, else if platform is set scroll to platform
  if (props.platform) {
    setTimeout(() => {
      $lenisScroll(categoriesContainer.value);
    }, 100);
  } else if (props.game) {
    setTimeout(() => {
      $lenisScroll(platformContainer.value);
    }, 100);
  }
});
</script>