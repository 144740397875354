<template>
  <Disclosure ref="disclosure"
              :key="key"
              :default-open="defaultOpen"
              as="div"
              v-slot="{ open }"
              :id="id"
              class="scroll-mt-[18vw] md:scroll-mt-[6vw] flex bg-indigo/10 rounded-[2.5vw] md:rounded-[1.25vw] flex-col"
  >
    <DisclosureButton class="p-[5vw] md:p-[1.75vw] space-x-[4vw] md:space-x-[2vw] focus:outline-none text-[4.25vw] md:text-[1.25vw] items-center md:leading-[1.875vw] leading-[6vw] text-left font-semibold flex justify-between">
      <span class="flex-grow">{{ title }}</span>
      <IconArrowDownSFill class="w-[6vw] h-[6vw] md:w-[2.5vw] md:h-[2.5vw] transform duration-150 flex-shrink-0 "
                          :class="open ? '-scale-y-100' : 'scale-y-100'"
      />
    </DisclosureButton>
    <TransitionExpand>
      <DisclosurePanel class="md:max-w-[47.5vw]">
        <div class="px-[5vw] pb-[5vw] md:px-[1.75vw] md:pb-[1.75vw] disclosure prose"
             ref="panel"
             v-html="content"
        />
      </DisclosurePanel>
    </TransitionExpand>
  </Disclosure>
</template>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue';
import { useState } from '#imports';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

type AccordionDisclosureProps = {
    id?: string;
    title: string;
    content: string
}

const props = defineProps<AccordionDisclosureProps>();
const disclosure = ref(null);
const defaultOpen = ref(false);
const panel = ref(null);

// Read route anchor if exists
const route = useRoute();
const anchor = computed(() => route.hash);
const key = ref('initial');
const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');

watch(panel, value => {
  if (value) {
    const links = value.querySelectorAll('a[data-video-id]');

    links.forEach((link: HTMLAnchorElement) => {
      link.addEventListener('click', handleVideoLinkClick);
    });
  }
});

function handleVideoLinkClick(this: HTMLAnchorElement, e: Event) {
  e.preventDefault();

  isOpen.value = true;
  playlist.value = this.getAttribute('data-video-id');
}

// watch for anchor changes
watch(anchor, value => {
  if (value === `#${props.id}` && props.id) {
    // Since the disclosure component can't be controlled programatically, we
    // force a rerender by changing the key prop and we set the default open state
    // to true
    defaultOpen.value = true;
    key.value = 'changed';
  }
},{ immediate: true });


</script>

<style scoped>
.disclosure a {
  @apply underline;
}

</style>